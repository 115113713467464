import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import Container from '~components/container/container.component';
import TopMenu from '~components/top-menu/top-menu.component';
import MainMenu from '~components/main-menu/main-menu.component';
import MobileMenu from '~components/mobile-menu/mobile-menu.component';
import Logo from '~components/logo/logo.component';
import SearchForm from '~components/search-form/search-form.component';
import SearchToggle from '~components/search-toggle/search-toggle.component';

import { headerUpdateHeight } from '~state/header';

import styles from './header.module.scss';

const HeaderTop = () => (
  <div className={`${styles.headerTop} hidden-mobile`}>
    <Container>
      <div className={styles.headerTopInner}>
        <div>This site is intended for US healthcare professionals.</div>
        <TopMenu />
        <SearchForm />
      </div>
    </Container>
  </div>
);

const HeaderMain = () => (
  <div className={styles.headerMain}>
    <Container>
      <nav className={styles.headerNav} role="navigation">
        <Logo />
        <div className="hidden-desktop">
          <SearchToggle />
          <SearchForm />
        </div>
        <ToggleMenu handleClick={handleToggleMenuButton} />
        <MainMenu />
      </nav>
      <MobileMenu />
    </Container>
  </div>
);

const ToggleMenu = ({ handleClick }) => (
  <button
    type="button"
    aria-label="Toggle navigation"
    className={`${styles.toggleMenuButton} hidden-desktop`}
    onClick={handleClick}
  >
    <i className="fas fa-bars fa-3x"></i>
  </button>
);

const handleToggleMenuButton = () => {
  document.querySelector('.toggle-menu').classList.toggle('active');
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.refHeader = createRef();
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    const { handleHeaderUpdateHeight } = this;
    handleHeaderUpdateHeight();
    window.addEventListener("resize", handleHeaderUpdateHeight);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleHeaderUpdateHeight);
  }

  handleHeaderUpdateHeight = () => {
    const { dispatch, headerHeight } = this.props;
    const updatedHeight = this.refHeader.current.clientHeight;
    if (headerHeight === updatedHeight) {
      return;
    }

    dispatch(headerUpdateHeight(updatedHeight));
  }

  render() {
    const { refHeader } = this;
    const { pageTabs } = this.props;
    return (
      <header ref={refHeader} className={styles.header}>
        <HeaderTop />
        <HeaderMain />
        {pageTabs}
      </header>
    );
  }
}

const mapStateToProps = ({header: {headerHeight}}) => {
  return {
    headerHeight,
  }
};

export default connect(mapStateToProps)(Header);
