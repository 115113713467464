import React from 'react';
import { login, logout, isAuthenticated } from '~utils/auth';

const Login = ({ withIcon }) => {
  let path, text, onClikHandler;

  if (!isAuthenticated()) {
    path = "#login";
    onClikHandler = e => {
      e.preventDefault();
      login(window.location.pathname);
    };
    text = "Log in";
  } else {
    path = "#logout"
    onClikHandler = e => {
      e.preventDefault();
      logout();
    };
    text = "Log out";
  }

  return (
    <a href={path} onClick={onClikHandler}>
      {text}
      {withIcon && <i className="fas fa-user-circle"></i>}
    </a>
  )
}

export default Login;
