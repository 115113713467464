import React from 'react'
import { BtnSecondary } from '../buttons/buttons.component'

import styles from './footer-cta.module.scss'

const FooterCta = () => (
  <div className={styles.footerCta}>
    <h2 className={styles.footerCtaTitle}>Have an Eagle Pharmaceuticals representative contact me</h2>
    <p className={styles.footerCtaBody}>Want to know more about Barhemsys? Connect with an Eagle Pharmaceuticals representative to learn more about Barhemsys. Ask about availability and how to get Barhemsys on formulary at your institution today.</p>
    <BtnSecondary path="/request-conversation">Contact Us</BtnSecondary>
  </div>
);

export default FooterCta;
