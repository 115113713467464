import React from 'react';
import { Link } from 'gatsby';

import styles from './main-menu.module.scss';

export const mainMenuItems = [
  {
    'path': '/ponv-impact/',
    'text': 'Adverse Impact of PONV'
  },
  {
    'path': '/why-barhemsys/',
    'text': 'Why Barhemsys'
  },
  {
    'path': '/efficacy/',
    'text': 'Proven Efficacy'
  },
  {
    'path': '/safety/',
    'text': 'Safety'
  },
  {
    'path': '/dosing-and-administration/',
    'text': 'Dosing & Administration'
  },
  {
    'path': '/ordering-info/',
    'text': 'Ordering & Reimbursement'
  },
  {
    'path': '/resources-learning/',
    'text': 'Resources & Learning Center'
  }
];

// Sub items are not needed now.
// Commented out this in case they will be needed later.
// const SubMenu = ({ subItems }) => (
//   <ul className={styles.subMenu}>
//     {subItems.map((link, i) => (
//       <li key={i} className={styles.subMenuItem}>
//         <Link to={link.path}>{link.text}</Link>
//       </li>
//     ))}
//   </ul>
// );

const MainMenu = () => {
  return (
    <ul className={`${styles.mainMenu} hidden-mobile`}>
      {mainMenuItems.map((link, i) => (
        <li key={i} className={styles.mainMenuItem}>
          <Link
            className={styles.mainMenuItemLink}
            to={link.path}
            activeClassName={styles.mainMenuItemLinkActive}
            partiallyActive={true}
          >
            {link.text}
          </Link>
          {/* {link.subItems && <SubMenu subItems={link.subItems} />} */}
        </li>
      ))}
    </ul>
  );
};

export default MainMenu;
