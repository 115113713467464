import React from 'react'
import { Link } from 'gatsby'

import styles from './footer-menu.module.scss'

const ListLink = ({ type, href, children }) => (
  <li className={styles.footerMenuItem}>
    {type === 'ext' ? <a href={href} target="_blank" rel="noreferrer">{children}</a> : <Link to={href}>{children}</Link>}
  </li>
)

export default () => (
  <ul className={styles.footerMenu}>
    <ListLink type="ext" href="https://www.eagleus.com/contact/">Contact Us</ListLink>
    <ListLink type="ext" href="https://acaciapharma.com/terms-conditions">Terms and Conditions</ListLink>
    <ListLink type="int" href="/site-map/">Site Map</ListLink>
    <ListLink type="ext" href="https://acaciapharma.com/privacy-policy">Privacy Policy</ListLink>
    <ListLink type="ext" href="https://acaciapharma.com/cookie-policy">Cookies Policy</ListLink>
  </ul>
)
