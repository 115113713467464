import React from 'react';
import Container from '~components/container/container.component';

import styles from './hero-banner.module.scss';

const HeroBanner = ({ title }) => {
  return (
    <div className={styles.banner}>
      <Container>
        <h1 className={styles.title}>{title}</h1>
      </Container>
    </div>
  )
};

export default HeroBanner;
