import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import styles from './logo.module.scss';

export default () => {
  const data = useStaticQuery(
    graphql`
      {
        logo: file(relativePath: { eq: "Barhemsys-logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <a className={styles.logo} href="/">
      <Img
        alt="Barhemsys® (amisulpride) logo"
        fluid={data.logo.childImageSharp.fluid}
      />
    </a>
  )
}
