import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';
import Header from '~components/header/header.component';
import HeroBanner from '~components/hero-banner/hero-banner.component';
import Container from '~components/container/container.component';
import Isi from '~components/isi/isi.component';
import Footer from '~components/footer/footer.component';
import Modal from '~components/modal/confirmation/confirmation.component';
import ScheduleConversation from '../modal/schedule/schedule.component';


import '@fortawesome/fontawesome-free/js/all';
import styles from './page-wrapper.module.scss';

const References = ({ text }) => (
  <div className={styles.references}>
    <RenderMarkdown text={text} />
  </div>
);

const PageWrapper = ({ children, pageTabs, hero, heroBanner, references }) => (
  <>
    <Header pageTabs={pageTabs} />
    <main>
      {hero}
      {heroBanner && <HeroBanner title={heroBanner} />}
      <div className={styles.mainContent}>
        <Container>
          {children}
          <Isi />
          {references && <References text={references.references ? references.references : references} />}
        </Container>
      </div>
    </main>
    <Footer />
    <Modal />
    <ScheduleConversation />
  </>
);

export default PageWrapper;
