import React from 'react';
import ReactModal from 'react-modal';

import styles from './modal.module.scss';

ReactModal.setAppElement('#___gatsby');

const Modal = ({ children, showModal, overrideProps }) => {
  const modalProps = {
    isOpen: showModal,
    closeTimeoutMS: 1000,
    className: styles.content,
    overlayClassName: {
      base: styles.ReactModal__Overlay,
      afterOpen: styles.ReactModal__OverlayAfterOpen,
      beforeClose: styles.ReactModal__OverlayBeforeClose,
    }
  };

  const props = {...modalProps, ...overrideProps};

  return (
    <ReactModal
      {...props}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;
