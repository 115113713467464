import React from 'react';

import './search-toggle.module.scss';

const handleToggleSearch = (e) => {
  const searchForm = document.querySelectorAll('.search-form');
  const btnOpen = e.target.closest('.search-toggle').parentNode.querySelector('.search-toggle--open');
  const btnClose = e.target.closest('.search-toggle').parentNode.querySelector('.search-toggle--close');

  searchForm.forEach((form) => {
    form.classList.toggle('show');

    if (form.classList.contains('show')) {
      btnOpen.style.display = 'none';
      btnClose.style.display = 'block';
    } else {
      btnOpen.style.display = 'block';
      btnClose.style.display = 'none';
    }
  })
};

const SearchToggle = () => (
  <>
    <button
      className="search-toggle search-toggle--open"
      aria-label="Open search box"
      onClick={handleToggleSearch}
    >
      <i className="fas fa-search"></i>
    </button>
    <button
      className="search-toggle search-toggle--close"
      aria-label="Close search box"
      onClick={handleToggleSearch}
    >
      <i className="fas fa-times"></i>
    </button>
  </>
);

export default SearchToggle;
