import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '~components/container/container.component';

import styles from './isi.module.scss';

const sessionName = 'isIsiClosed';

const Isi = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulComponent(contentful_id: {eq: "6yMDW1WqoZNto9YhKrLJSr"}) {
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);

  const isiStatic = useRef(null);
  const isiFixed = useRef(null);
  const btnExpand = useRef(null);
  const btnCollapse = useRef(null);

  let isIsiClosed = false;
  if (typeof window !== 'undefined') {
    isIsiClosed = sessionStorage.getItem(sessionName) ? true : false;
  }
  const [hideIsi, setHideIsi] = useState(isIsiClosed);

  useEffect(() => {
    if (!isiFixed.current || hideIsi) {
      return;
    }
    setHideIsi(isIsiClosed);

    if (isiStatic.current.getBoundingClientRect().top < window.innerHeight * 0.75) {
      setHideIsi(true);
    } else {
      // Throttling scroll event
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event#Examples
      let isiPosition = 0;
      let ticking = false;

      window.addEventListener('scroll', () => {
        if (isiFixed.current && !isiFixed.current.classList.contains('closed')) {
          isiPosition = isiStatic.current.getBoundingClientRect().top;

          if (!ticking) {
            window.requestAnimationFrame(function() {
              if (isiPosition < window.innerHeight * 0.75) {
                isiFixed.current.style.display = 'none';
              } else {
                isiFixed.current.style.display = 'block';
              }
              ticking = false;
            });

            ticking = true;
          }
        }
      });
    }
  }, [isIsiClosed, hideIsi]);

  const handleExpandButton = () => {
    isiFixed.current.classList.add('active');
  };

  const handleCollapseButton = () => {
    isiFixed.current.classList.remove('active');
    isiFixed.current.classList.add('closed');
    setTimeout(() => {
      sessionStorage.setItem(sessionName, true);
      setHideIsi(true);
    }, 1000);
  };

  return (
    <>
      <div
        className={[styles.isi, styles.isiStatic].join(' ')}
        id="isi"
        ref={isiStatic}
        dangerouslySetInnerHTML={{
          __html: data.contentfulComponent.body.childMarkdownRemark.html,
        }}
      />
      {!hideIsi &&
        <div ref={isiFixed} className={styles.isiFixed}>
          <Container>
            <button
              ref={btnExpand}
              type="button"
              aria-label="Expand Important Safety Information"
              className={styles.btnExpand}
              onClick={handleExpandButton}
            >
              <i className="fas fa-plus"></i>Expand
            </button>
            <button
              ref={btnCollapse}
              type="button"
              aria-label="Collapse Important Safety Information"
              className={styles.btnCollapse}
              onClick={handleCollapseButton}
            >
              <i className="fas fa-minus"></i>Collapse
            </button>
            <div
              className={styles.isiContent}
              dangerouslySetInnerHTML={{
                __html: data.contentfulComponent.body.childMarkdownRemark.html,
              }}
            />
          </Container>
        </div>
      }
    </>
  )
};

export default Isi;
