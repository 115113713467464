import React from 'react';
import ReactMarkdown from "react-markdown";
import remarkSubSuper from "remark-sub-super";

const RenderMarkdown = ({text, paragraph = 'p' }) => {
  if (!text) {
    return null;
  }
  return (
    <ReactMarkdown
      source={text}
      plugins={[remarkSubSuper]}
      renderers={{
        sub: 'sub',
        sup: 'sup',
        paragraph,
      }}
    />
  );
}

export default RenderMarkdown;
